<template>
  <v-container class="pa-0" fluid>
    <v-card elevation="0">
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"  class="card-header">
        {{ this.$t('customers.lang_customerDepartments') }}

        <v-spacer/>

        <v-btn @click="openCreateDialog" color="primary" depressed>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-0">
        <Datatable @deleteEntries="deleteEntries" @deleteEntry="deleteEntry" @editEntry="setEntry" no-excel-export
                   show-delete-buttons
                   show-edit-buttons ref="departments"
                   :api-endpoint="ENDPOINTS.DATATABLES.PMS.SETTINGS.DEPARTMENTS"
                   :datatable-headers="datatableHeaders"
        />
      </v-card-text>
    </v-card>


    <!-- start: add department dialog -->
    <v-dialog max-width="900" v-model="addDialog">
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
          {{ $t('customers.lang_customerDepartmentsAdd') }}
        </v-card-title>

        <v-card-text class="py-4">
          <v-form lazy-validation ref="addForm">
            <v-row class="ma-0">
              <v-col class="pb-0" cols="12">
                <v-text-field @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]" v-model="addForm.name" :label="this.$t('generic.lang_name')"
                              dense outlined/>
              </v-col>

              <v-col class="pt-0" cols="12" sm="6">
                <v-text-field @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v && Number(v) > 0]" v-model="addForm.total_rooms"
                              :label="this.$t('erp.lang_rooms')" dense outlined/>
              </v-col>

              <v-col class="pt-0" cols="12" sm="6">
                <v-text-field @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v && Number(v) > 0]" v-model="addForm.capacity"
                              :label="this.$t('generic.lang_capacity')" dense outlined/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="card-footer">
          <v-btn @click="closeCreateDialog" text color="error">{{ this.$t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <v-btn :loading="this.loading" :disabled="this.loading" @click="createDepartment" text color="primary">
            {{ this.$t('generic.lang_create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: add department dialog -->


    <!-- start: edit department dialog -->
    <v-dialog max-width="900" v-model="editDialog">
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
          {{ $t('customers.lang_customerDepartmentsEdit') }}
        </v-card-title>

        <v-card-text class="py-4">
          <v-form lazy-validation ref="editForm">
            <v-row class="ma-0">
              <v-col class="pb-0" cols="12">
                <v-text-field @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]" v-model="editForm.name"
                              :label="this.$t('generic.lang_name')"
                              dense outlined/>
              </v-col>

              <v-col class="pt-0" cols="12" sm="6">
                <v-text-field @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v && Number(v) > 0]"
                              v-model="editForm.total_rooms"
                              :label="this.$t('erp.lang_rooms')" dense outlined/>
              </v-col>

              <v-col class="pt-0" cols="12" sm="6">
                <v-text-field @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v && Number(v) > 0]"
                              v-model="editForm.capacity"
                              :label="this.$t('generic.lang_capacity')" dense outlined/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="card-footer">
          <v-btn @click="closeEditDialog" text color="error">{{ this.$t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <v-btn :loading="this.loading" :disabled="this.loading" @click="editDepartment" text color="primary">
            {{ this.$t('generic.lang_edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: edit department dialog -->


    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from '@/mixins/KeyboardMixIns'

export default {
  name: "PmsDepartmentsListComponent",
  components: {
    Datatable
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      addDialog: false,
      editDialog: false,
      loading: false,
      addForm: {
        name: "",
        total_rooms: 0,
        capacity: 0
      },
      editForm: {
        id: "",
        name: "",
        total_rooms: "",
        capacity: ""
      }
    }
  },
  computed: {
    datatableHeaders() {
      return [
        {
          text: 'uuid',
          value: "id",
          hide: true
        },
        {
          text: this.$t('generic.lang_name'),
          value: "name",
        },
        {
          text: this.$t('erp.lang_rooms'),
          value: "total_rooms",
        },
        {
          text: this.$t('generic.lang_capacity'),
          value: "capacity",
        }
      ]
    }
  },
  methods: {
    /**
     * add a new department
     */
    createDepartment() {
      if (!this.$refs.addForm.validate()) return;

      this.loading = true;

      this.axios
          .post(ENDPOINTS.PMS.SETTINGS.DEPARTMENTS.CREATE, this.addForm)
          .then((res) => {
            if (res.data.success) {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_itemSuccessfulAdded"),
                color: "success",
              });
              this.$refs.addForm?.reset();
              this.$refs.departments?.getDataFromApi();
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    openCreateDialog() {
      this.$refs.addForm?.reset();
      this.addDialog = true;
    },
    closeCreateDialog() {
      this.$refs.addForm?.reset();
      this.addDialog = false;
    },
    openEditDialog() {
      this.$refs.editForm?.reset();
      this.editDialog = true;
    },
    closeEditDialog() {
      this.$refs.editForm?.reset();
      this.editDialog = false;
    },
    /**
     * set emitted department from datatable then open the edit dialog
     * @param {Object} department
     */
    setEntry(department) {
      this.openEditDialog();
      this.editForm = {...department};
    },
    editDepartment() {
      if (!this.$refs.editForm.validate()) return;

      this.loading = true;
      this.axios
          .post(ENDPOINTS.PMS.SETTINGS.DEPARTMENTS.UPDATE, this.editForm)
          .then((res) => {
            if (res.data.success) {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_itemSuccessfulEdited"),
                color: "success",
              });
              this.closeEditDialog();
              this.$refs.departments?.getDataFromApi();
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    deleteEntry({id}) {
      this.deleteDepartments([id])
    },
    deleteEntries(ids) {
      this.deleteDepartments(ids)
    },
    /**
     * delete list of giving departments
     * @param ids
     */
    deleteDepartments(ids = []) {
      this.$swal({
        title: this.$t('erp.lang_delete_pms'),
        text: this.$t('erp.lang_pms_delete_confirm'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        preConfirm: () => {

          this.axios.post(ENDPOINTS.PMS.SETTINGS.DEPARTMENTS.DELETE, {
            ids
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_itemSuccessfulDeleted'),
                color: "success"
              });

              this.$refs.departments?.getDataFromApi();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    }
  }
}
</script>

<style scoped>

</style>
