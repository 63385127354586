<template>
  <v-container fluid>

    <v-row>
      <!--menu-->
      <v-col cols="12" sm="12" md="3">
        <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-1']" no-body>
          <b-card-header class="p-0" header-tag="header" role="tab">
            <v-list-item @click="opened =! opened" class="ma-0"
                         v-b-toggle.global-settings>
              <v-list-item-title :class="this.opened? 'primary--text text-wrap' : 'text-wrap'">
                {{ this.$t('settings.lang_settings') }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="!this.opened">keyboard_arrow_down</v-icon>
                <v-icon v-if="this.opened">keyboard_arrow_up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </b-card-header>
          <b-collapse :visible="this.opened" accordion="global-settings" id="global-settings"
                      role="tabpanel">
            <b-card-body class="pa-0">
              <b-list-group flush>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'departments'? 'primary--text' : '']"
                                   @click="handleClick('departments')"
                                   class="items">
                  {{ this.$t('customers.lang_customerDepartments') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'global_settings'? 'primary--text' : '']"
                                   @click="handleClick('global_settings')"
                                   class="items">
                  {{ this.$t('generic.lang_globalSettings') }}
                </b-list-group-item>

              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>

      </v-col>

      <!--content-->
      <v-col class="py-0" cols="12" sm="12" md="9">
        <v-card>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col>
                  <template>
                    <div role="tablist">
                      <pms-departments-list-component v-if="currentTab === 'departments'"/>

                      <pms-global-settings-component v-if="currentTab === 'global_settings'"/>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import PmsDepartmentsListComponent from "@/components/pms/settings/PmsDepartmentsListComponent";
import PmsGlobalSettingsComponent from "@/components/pms/settings/PmsGlobalSettingsComponent";

export default {
  name: "PmsSettingsComponent",
  components: {PmsGlobalSettingsComponent, PmsDepartmentsListComponent},
  data() {
    return {
      currentTab: "departments",
      opened: true
    }
  },
  methods: {
    handleClick(newTab) {
      if (newTab && newTab.length > 0) {
        this.currentTab = newTab;
        this.$router.push({query: {tab: newTab}})
      }
    },
  },
  mounted() {
    this.handleClick(this.$route.query.tab)
  }
}
</script>

<style scoped>
.items {
  cursor: pointer;
}
</style>
