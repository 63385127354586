<template>
  <v-container class="pa-0" fluid>
    <v-card elevation="0">
      <v-card-title :class="[this.$vuetify.theme.dark? 'white--text' : 'card-header']">
        {{ this.$t('generic.lang_globalSettings') }}
      </v-card-title>

      <v-card-text class="pt-6">
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col cols="12">
              <BaseTimeInput v-model="form.pms_reset_time" :label="$t('generic.lang_resetTime')" dense outlined/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="card-footer py-1 px-1">
        <v-spacer/>
        <v-btn @click="saveSettings" :disabled="this.loading" :loading="this.loading" color="primary" depressed>
          {{ this.$t('generic.lang_save') }}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import BaseTimeInput from "@/components/common/BaseTimeInput.vue";

export default {
  name: "PmsGlobalSettingsComponent",
  components: { BaseTimeInput },
  data() {
    return {
      loading: false,
      form: {
        pms_reset_time: ""
      }
    }
  },
  methods: {
    saveSettings() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.axios
          .post(ENDPOINTS.PMS.SETTINGS.GLOBALSETTINGS.UPDATE, this.form)
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_actionSuccessful"),
                color: "success",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getSettings() {

      this.loading = true;

      this.axios
          .post(ENDPOINTS.PMS.SETTINGS.GLOBALSETTINGS.GET)
          .then((res) => {
            if (res.status) {
              this.form = {...res.data}
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
  mounted() {
    this.getSettings();
  }
}
</script>

<style scoped>

</style>
