<template>
  <div>
    <page-title :heading="$t('settings.lang_settings')" :icon=icon :subheading="$t('settings.lang_settings')"
    ></page-title>
    <div class="app-main__inner">
      <pms-settings-component/>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/Layout/Components/PageTitle";
import PmsSettingsComponent from "@/components/pms/PmsSettingsComponent";


export default {
  name: "PmsSettingsView",
  components: {
    PmsSettingsComponent,

    PageTitle,
  },
  data: () => ({
    icon: 'pe-7s-settings icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>

